<template>
    <div class="">
        <div
            class="card-table"
            :class="mode ? 'card__tableday' : 'card__tablenight'"
        >
            <div class="card-table-body ifon-add-title-block">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div class="caz-blocks-sarcho-title">
                            <div
                                :class="
                                    mode
                                        ? 'content__titleday'
                                        : 'content__titlenight'
                                "
                                class="content-title d-flex align-center mr-2"
                            >
                                Заявки
                            </div>
                            <div class="block-sarche">
                                <div class="header__search">
                                    <crm-input
                                        :size="'small'"
                                        :className="'w100'"
                                        :class="
                                            mode ? 'input__day' : 'input__night'
                                        "
                                        v-model="filterForm.search"
                                        :icon="'el-icon-search'"
                                    ></crm-input>
                                </div>
                            </div>
                        </div>
                    </el-col>

                    <el-col :span="12" class="flex-style text-right">
                        <crm-create-and-column-settings
                            :permission="$options.name"
                            @c-create="drawerCreate = true"
                            :class="
                                mode
                                    ? 'button__settingsday'
                                    : 'button__settingsnight'
                            "
                            :columns="columns"
                            @c-change="updateColumn"
                        >
                        </crm-create-and-column-settings>
                    </el-col>
                </el-row>
            </div>
            <!-- end ifon-add-title-block -->

            <div>
                <ul class="nav-item-top">
                    <li>
                        <router-link
                            class="nav-item-top-link"
                            :to="{ name: 'texnoApplicationsAll' }"
                        >
                            Все
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            class="nav-item-top-link action"
                            :to="{ name: 'texnoApplicationsNew' }"
                        >
                            Новые
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            class="nav-item-top-link"
                            :to="{ name: 'texnoApplicationsAccepted' }"
                        >
                            Принятые
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            class="nav-item-top-link"
                            :to="{ name: 'texnoApplicationsWaiting' }"
                        >
                            В ожидании зап. частей
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            class="nav-item-top-link"
                            :to="{ name: 'texnoApplicationsProcess' }"
                        >
                            В процессе
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            class="nav-item-top-link"
                            :to="{ name: 'texnoApplicationsCompleted' }"
                        >
                            Завершенные
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            class="nav-item-top-link bg-red"
                            :to="{ name: '/' }"
                        >
                            Отказанные
                        </router-link>
                    </li>
                </ul>
            </div>

            <div class="card-table-header table-crm-smart">
                <table
                    class="table-my-code table-bordered"
                    :class="mode ? 'table__myday' : 'table__mynight'"
                    v-loading="loadingData"
                >
                    <thead>
                        <tr>
                            <th class="w50p" v-if="columns.id.show">
                                {{ columns.id.title }}
                            </th>

                            <th v-if="columns.Operator.show">
                                {{ columns.Operator.title }}
                            </th>

                            <th v-if="columns.cilent.show">
                                {{ columns.cilent.title }}
                            </th>

                            <th v-if="columns.serviceDate.show">
                                {{ columns.serviceDate.title }}
                            </th>

                            <th v-if="columns.Service.show">
                                {{ columns.Service.title }}
                            </th>

                            <th v-if="columns.Provider.show">
                                {{ columns.Provider.title }}
                            </th>

                            <th v-if="columns.typeInfo.show">
                                {{ columns.typeInfo.title }}
                            </th>

                            <th v-if="columns.dataContract.show">
                                {{ columns.dataContract.title }}
                            </th>

                            <th v-if="columns.data.show">
                                {{ columns.data.title }}
                            </th>

                            <th v-if="columns.click.show">
                                {{ columns.click.title }}
                            </th>

                            <th v-if="columns.accept.show">
                                {{ columns.accept.title }}
                            </th>

                            <th v-if="columns.Reject.show">
                                {{ columns.Reject.title }}
                            </th>
                        </tr>

                        <tr class="filter_sorche">
                            <th v-if="columns.id.show">
                                <el-input
                                    :class="mode ? 'mode_1' : 'mode__2'"
                                    clearable
                                    size="mini"
                                    v-model="filterForm.id"
                                    :placeholder="columns.id.title"
                                    class="id_input"
                                ></el-input>
                            </th>

                            <th v-if="columns.Operator.show">
                                <el-input
                                    :class="mode ? 'mode_1' : 'mode__2'"
                                    clearable
                                    size="mini"
                                    v-model="filterForm.Operator"
                                    :placeholder="columns.Operator.title"
                                ></el-input>
                            </th>

                            <th v-if="columns.cilent.show">
                                <el-input
                                    :class="mode ? 'mode_1' : 'mode__2'"
                                    clearable
                                    size="mini"
                                    v-model="filterForm.cilent"
                                    :placeholder="columns.cilent.title"
                                ></el-input>
                            </th>

                            <th v-if="columns.serviceDate.show">
                                <el-input
                                    :class="mode ? 'mode_1' : 'mode__2'"
                                    clearable
                                    size="mini"
                                    v-model="filterForm.serviceDate"
                                    :placeholder="columns.serviceDate.title"
                                ></el-input>
                            </th>

                            <th v-if="columns.Service.show">
                                <el-input
                                    :class="mode ? 'mode_1' : 'mode__2'"
                                    clearable
                                    size="mini"
                                    v-model="filterForm.Service"
                                    :placeholder="columns.Service.title"
                                ></el-input>
                            </th>

                            <th v-if="columns.Provider.show">
                                <el-input
                                    :class="mode ? 'mode_1' : 'mode__2'"
                                    clearable
                                    size="mini"
                                    v-model="filterForm.Provider"
                                    :placeholder="columns.Provider.title"
                                ></el-input>
                            </th>

                            <th v-if="columns.typeInfo.show">
                                <el-input
                                    :class="mode ? 'mode_1' : 'mode__2'"
                                    clearable
                                    size="mini"
                                    v-model="filterForm.typeInfo"
                                    :placeholder="columns.typeInfo.title"
                                ></el-input>
                            </th>

                            <th v-if="columns.dataContract.show">
                                <el-date-picker
                                    :class="mode ? 'mode_1' : 'mode__2'"
                                    :placeholder="columns.dataContract.title"
                                    v-model="filterForm.dataContract"
                                    size="mini"
                                >
                                </el-date-picker>
                            </th>

                            <th v-if="columns.data.show">
                                <el-date-picker
                                    :class="mode ? 'mode_1' : 'mode__2'"
                                    :placeholder="columns.data.title"
                                    v-model="filterForm.data"
                                    size="mini"
                                >
                                </el-date-picker>
                            </th>

                            <th v-if="columns.click.show"></th>

                            <th v-if="columns.accept.show"></th>

                            <th v-if="columns.Reject.show"></th>
                        </tr>
                    </thead>

                    <transition-group name="flip-list" tag="tbody">
                        <tr
                            v-for="user in list"
                            :key="user.id"
                            class="cursor-pointer"
                        >
                            <td v-if="columns.id.show">{{ user.id }}</td>

                            <td v-if="columns.Operator.show">
                                {{ user.Operator }}
                            </td>

                            <td v-if="columns.cilent.show">
                                {{ user.cilent }}
                            </td>

                            <td v-if="columns.serviceDate.show">
                                {{ user.serviceDate }}
                            </td>

                            <td v-if="columns.Service.show">
                                {{ user.Service }}
                            </td>

                            <td v-if="columns.Provider.show">
                                {{ user.Provider }}
                            </td>

                            <td v-if="columns.typeInfo.show">
                                {{ user.typeInfo }}
                            </td>

                            <td v-if="columns.dataContract.show">
                                {{ user.dataContract }}
                            </td>

                            <td v-if="columns.data.show">{{ user.data }}</td>

                            <td v-if="columns.click.show">
                                <el-button
                                    size="mini"
                                    class="w-100"
                                    type="primary"
                                >
                                    Завершение 0%
                                </el-button>
                            </td>

                            <td v-if="columns.accept.show">
                                <el-button
                                    size="mini"
                                    class="w-100"
                                    type="success"
                                    @click="dialogAccept = true"
                                >
                                    Принять
                                </el-button>
                            </td>

                            <td v-if="columns.Reject.show">
                                <el-button
                                    @click="dialogReject = true"
                                    size="mini"
                                    class="w-100"
                                    type="danger"
                                >
                                    Отклонить
                                </el-button>
                            </td>
                        </tr>
                    </transition-group>
                </table>
                <div class="my___pagination">
                    <crm-pagination
                        @c-change="updatePagination"
                        :class="mode ? 'pagination__day' : 'pagination__night'"
                        :pagination="pagination"
                    ></crm-pagination>
                    <!-- <Pagination /> -->
                </div>
            </div>
        </div>

        <el-dialog
            title="Вы действительно хотите принять заявку?"
            :visible.sync="dialogAccept"
            width="30%"
            :before-close="handleClose"
        >
            <span>
                <el-form ref="form" :model="form">
                    <el-form-item label="Поставщик услуг (Мастер)">
                        <el-select
                            class="w-100"
                            v-model="form.region"
                            placeholder="Поставщик услуг (Мастер)"
                        >
                            <el-option
                                label="Zone one"
                                value="shanghai"
                            ></el-option>
                            <el-option
                                label="Zone two"
                                value="beijing"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer text-center">
                    <el-button type="danger" @click="dialogAccept = false"
                        >Закрыть</el-button
                    >
                    <el-button type="primary" @click="dialogAccept = false">
                        Принять заявку
                    </el-button>
                </span>
            </span>
        </el-dialog>

        <el-dialog
            title="Вы действительно хотите принять заявку?"
            :visible.sync="dialogReject"
            width="30%"
            :before-close="handleClose"
        >
            <span>
                <el-form ref="form" :model="form">
                    <el-form-item label="Поставщик услуг (Мастер)">
                        <el-select
                            class="w-100"
                            v-model="form.region"
                            placeholder="Поставщик услуг (Мастер)"
                        >
                            <el-option
                                label="Zone one"
                                value="shanghai"
                            ></el-option>
                            <el-option
                                label="Zone two"
                                value="beijing"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Комментарий">
                        <el-input
                            type="textarea"
                            v-model="form.desc"
                        ></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer text-center">
                    <el-button type="danger" @click="dialogReject = false">
                        Отклонить заявку
                    </el-button>
                </span>
            </span>
        </el-dialog>

        <div class="app-modal app-modal__full mg scrol">
            <el-drawer
                :with-header="false"
                :visible.sync="drawerCreate"
                size="97%"
            >
                <div>
                    <CrmCreate></CrmCreate>
                </div>
            </el-drawer>
        </div>
    </div>
</template>

<script>
import CrmCreate from "./components/crm-create";
export default {
    name: "texnoApplicationsNew",
    components: {
        CrmCreate,
    },
    data() {
        return {
            drawerCreate: false,
            dialogAccept: false,
            dialogReject: false,
            form: {},
            filterForm: {
                id: "",
                Operator: "",
                cilent: "",
                serviceDate: "",
                Service: "",
                Provider: "",
                typeInfo: "",
                dataContract: "",
                data: "",
                click: "",
                accept: "",
                Reject: "",
            },
            list: [
                {
                    id: "8",
                    Operator: "Оператор 1",
                    cilent: "Клиент 1",
                    serviceDate: "02.05.1995",
                    Service: "Монтаж кондиционера",
                    Provider: "ООО Texnogarant",
                    typeInfo: "Лифт",
                    dataContract: "02.05.1995",
                    data: "12.12.2020",
                    click: "",
                    accept: "",
                    Reject: "",
                },
            ],
            columns: {
                id: {
                    show: true,
                    title: "№",
                    sortable: true,
                    column: "id",
                },

                Operator: {
                    show: true,
                    title: "Оператор",
                    sortable: true,
                    column: "Operator",
                },

                cilent: {
                    show: true,
                    title: "Клиент",
                    sortable: true,
                    column: "cilent",
                },

                serviceDate: {
                    show: true,
                    title: "Дата поступления заявки",
                    sortable: true,
                    column: "serviceDate",
                },

                Service: {
                    show: true,
                    title: "Тип заявки",
                    sortable: true,
                    column: "Service",
                },

                Provider: {
                    show: true,
                    title: "Тип",
                    sortable: true,
                    column: "Provider",
                },

                typeInfo: {
                    show: true,
                    title: "Тип (заявки, информации, жалобы)",
                    sortable: true,
                    column: "typeInfo",
                },

                click: {
                    show: true,
                    title: "Завершение",
                    sortable: true,
                    column: "click",
                },

                accept: {
                    show: true,
                    title: "Принять",
                    sortable: true,
                    column: "accept",
                },

                Reject: {
                    show: true,
                    title: "Отклонить",
                    sortable: true,
                    column: "Reject",
                },

                dataContract: {
                    show: true,
                    title: "Дата поступления заявки",
                    sortable: true,
                    column: "dataContract",
                },
                data: {
                    show: false,
                    title: "Дата создания  ",
                    sortable: true,
                    column: "data",
                },
            },
        };
    },
};
</script>

